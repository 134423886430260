import React, { useState } from "react";
import * as XLSX from "xlsx";
import axios from "axios";
axios.defaults.withCredentials = true;

const ExcelUploaderWithPreview = () => {
    const [file, setFile] = useState(null);
    const [error, setError] = useState("");
    const [previewData, setPreviewData] = useState([]);
    const [updatedData, setUpdatedData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [msg, setMsg] = useState(null);

    // Predefined columns
    const predefinedColumns = [
        "Sr No.",
        "Name",
        "Phone",
        "Email",
        "Product",
        "Value",
        "City",
        "State",
        "Zip Code",
        "Employee",
        "Source",
        "Remark",
    ];

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        const allowedTypes = [
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.ms-excel",
            "text/csv",
        ];

        if (selectedFile) {
            if (allowedTypes.includes(selectedFile.type)) {
                setFile(selectedFile);
                setError("");
                previewFile(selectedFile);
            } else {
                setFile(null);
                setError("Please upload a valid file (.xlsx, .xls, or .csv).");
                setPreviewData([]);
            }
        }
    };
    const [errRows, setErrorRows] = useState([])

    const previewFile = (file) => {
        const reader = new FileReader();
        const fileExtension = file.name.split(".").pop().toLowerCase();

        reader.onload = (e) => {
            let data;
            if (fileExtension === "csv") {
                const csvData = e.target.result;
                const rows = csvData.split("\n").map((row) => row.split(","));
                data = rows.map((row, idx) => {
                    // Create an object for each row based on predefined columns
                    const rowObj = {};
                    predefinedColumns.forEach((col, index) => {
                        rowObj[col] = row[index] || ""; // Map to predefined column or leave blank
                    });
                    return rowObj;
                });
            } else {
                const binaryData = new Uint8Array(e.target.result);
                const workbook = XLSX.read(binaryData, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                data = XLSX.utils.sheet_to_json(sheet);
            }

            // Ensure rows match the extracted rows
            if (data.length > 0) {
                setPreviewData(data);
                const updated = updateColumnsWithPredefinedNames(data);
                setUpdatedData(updated);
            } else {
                setError("No data found in the uploaded file.");
            }
        };

        if (fileExtension === "csv") {
            reader.readAsText(file);
        } else {
            reader.readAsArrayBuffer(file);
        }
    };

    const updateColumnsWithPredefinedNames = (data) => {
        if (!data.length) return [];

        const originalColumns = Object.keys(data[0]);
        const mappedData = data.map((row) => {
            const newRow = {};
            predefinedColumns.forEach((col, index) => {
                const originalColumn = originalColumns[index] || `Unknown_${index}`;
                newRow[col] = row[originalColumn] || ""; // Map to predefined column or leave blank
            });
            return newRow;
        });

        return mappedData;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        if (!file) {
            setError("Please select a file before submitting.");
            setLoading(false);
            return;
        }

        try {
            await axios.post("https://salesmanagementbackend.basic2ai.info/api/register/customers/bulk", { updatedData })
                .then((res) => {
                    if (res.data.success) {
                        setMsg(res.data.msg);
                        window.location.reload(true);
                    }
                    console.log(res)
                    if (res.status == 400) {
                        setMsg(res.response.data.msg)
                    }
                    if (res.response.data.details.parent.errno == 1048)
                        setMsg(res.response.data.details.parent.sqlMessage)
                    console.log(res.response.data.errorRows.length)
                    console.log(res.response.data.errorRows)
                    if (res.response.data.errorRows.length > 0) {
                        setErrorRows((prevErrorRows) => [
                            ...prevErrorRows,
                            ...res.response.data.errorRows.map((row) => row.row),
                        ]);
                    }
                })
                .catch((err) => {
                    if (err.status == 400) {
                        setMsg(err.response.data.msg)
                    }
                    if (err.response.data.errorRows.length > 0) {
                        const newErrorRows = err.response.data.errorRows.map((item) => ({
                            row: item.row,
                            error: item.error,
                        }));
                        setErrorRows((prevErrorRows) => [...prevErrorRows, ...newErrorRows]);
                    }
                    // if (err.response.data.details.parent.errno == 1048)
                    //     setMsg(err.response.data.details.parent.sqlMessage)

                });
        } catch (err) {
            console.error("Error sending data to backend:", err);
            setError("Failed to send data to the backend.");
        }
        setLoading(false);
    };
    console.log(errRows)
    return (
        <>
            {loading ? (
                <div
                    className="d-flex border align-items-center justify-content-center"
                    style={{ minWidth: "350px", minHeight: "400px", width: "-webkit-fill-available" }}
                >
                    <b className="loader">Loading . . .</b>
                </div>
            ) : (
                <div className="p-2 shadow border" style={{ maxWidth: "", margin: "auto", background: "#81d4fa63" }}>
                    <h3 className="fw-bolder">Upload Excel or CSV File</h3>
                    <form onSubmit={handleSubmit}>
                        <div className="">
                            <input
                                className="form-control py-1"
                                type="file"
                                accept=".xlsx,.xls,.csv"
                                onChange={handleFileChange}
                                style={{ marginBottom: "10px" }}
                            />
                            <button type="submit" className="btn btn-success rounded-0 py-1">
                                Upload
                            </button>
                        </div>
                        {error && <p style={{ color: "red" }}>{error}</p>}
                    </form>
                    {errRows.length > 0 && (
                        <div className="bg-white p-1 shadow" style={{ marginTop: "20px", color: "red", maxHeight: '100px', overflow: 'auto' }}>
                            <h5>Error Rows:</h5>
                            <ul>
                                {(() => {
                                    const items = [];
                                    for (let i = 0; i < errRows.length - 1; i++) {
                                        const row = errRows[i];
                                        items.push(
                                            <li key={i}>
                                                <b className="">Row : </b>{row.row}{",  "}
                                                {row.error === "notNull Violation: ContactModel.mobile cannot be null"
                                                    ? "Mobile cannot be empty"
                                                    : ""}
                                            </li>
                                        );
                                    }
                                    return items;
                                })()}
                            </ul>
                        </div>
                    )}

                    {msg && <center className="bg-white py-2 shadow">{msg}</center>}
                    {previewData.length > 0 && (
                        <div className="bg-white p-2" style={{ marginTop: "20px" }}>
                            <h4>File Preview with Updated Columns</h4>
                            <div className="table-responsive" style={{ maxHeight: "500px", overflowY: "auto" }}>
                                <table
                                    border="1"
                                    className="table table-striped table-hover"
                                    cellPadding="5"
                                    style={{ width: "100%", borderCollapse: "collapse" }}
                                >
                                    <thead>
                                        <tr>
                                            {predefinedColumns.map((col) => (
                                                <th key={col} className="bg-primary text-white text-uppercase">
                                                    {col}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {updatedData.map((row, index) => (
                                            <tr key={index}>
                                                {predefinedColumns.map((col) => (
                                                    <td key={col}>{row[col]}</td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default ExcelUploaderWithPreview;
