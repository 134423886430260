import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../App';
axios.defaults.withCredentials = true;

const Employee = () => {
    const [employeeList, setEmployeeList] = useState([]);
    const [allotedLocations, setAllotedLocations] = useState({
        loc1: null,
        loc2: null,
        loc3: null
    });
    const [allUniqueZipCodes, setAllUniqueZipCodes] = useState([]);
    const { user } = useContext(UserContext);

    useEffect(() => {
        axios.get('https://salesmanagementbackend.basic2ai.info/api/all/customer')
            .then(res => {
                // Ensure the response data exists and is an array before proceeding
                if (res.data && res.data.data && Array.isArray(res.data.data)) {
                    const zipCodeArray = res.data.data.map(item => ({
                        zipCode: item.zipCode,
                        state: item.State // Assuming `State` is in the customer data
                    }));
    
                    // Handle uniqueness of zip codes
                    const uniqueZipCodes = Array.from(new Set(zipCodeArray.map(item => item.zipCode)))
                        .map(zip => zipCodeArray.find(item => item.zipCode === zip));
    
                    setAllUniqueZipCodes(uniqueZipCodes);
                } else {
                    console.error('Data structure is not as expected', res.data);
                }
            })
            .catch(err => {
                console.error('Error fetching data:', err);
            });
    }, []);
    

    useEffect(() => {
        axios.get('https://salesmanagementbackend.basic2ai.info/get_data_to_admin/employee')
            .then(res => {
                setEmployeeList(res.data.data);
            })
            .catch(err => {
                console.log(err);
            });
    }, []);

    const handleSelectLocation = (e, loc) => {
        const value = e.target.value;
        setAllotedLocations(prev => {
            const updated = { ...prev };

            if (loc === 1) {
                updated.loc1 = value === '-Zip Code 1-' ? null : value;
                updated.loc2 = null;
                updated.loc3 = null;
            } else if (loc === 2) {
                updated.loc2 = value === '-Zip Code 2-' ? null : value;
                if (!updated.loc2) updated.loc3 = null;
            } else if (loc === 3) {
                updated.loc3 = value === '-Zip Code 3-' ? null : value;
            }

            return updated;
        });
    };

    const handleLocationSave = (email) => {
        axios.post(`https://salesmanagementbackend.basic2ai.info/alloted_locations/${email}`, { allotedLocations })
            .then(res => {
                if (res.status === 200) {
                    alert(`Location Updated Successfully for ${email}.`);
                    window.location.reload(true);
                }
            })
            .catch(err => {
                console.log(err);
            });
    };

    return (
        <div>
            <div className='btn border-0'>Employee List</div>
            <div className='table-responsive'>
                <table className='table table-hover table-striped'>
                    <thead>
                        <tr>
                            <th>Username</th>
                            <th>Role</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Status</th>
                            <th>Location</th>
                            <th>Alloted Location</th>
                            <th>New Location</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {employeeList ? employeeList.map((data, idx) => (
                            <tr key={idx}>
                                <td>{data.username}</td>
                                <td>{data.role}</td>
                                <td>{data.email}</td>
                                <td>{data.phone}</td>
                                <td>{data.status}</td>
                                <td>{data.village}, {data.city}, {data.State} ({data.zipCode})</td>
                                <td>
                                    Loc1 - {data.locationPriority?.location1 || 'N/A'}, <br />
                                    Loc2 - {data.locationPriority?.location2 || 'N/A'}, <br />
                                    Loc3 - {data.locationPriority?.location3 || 'N/A'}, <br />
                                    Assigned By - {data.locationPriority?.assigned_by || 'N/A'}
                                </td>
                                <td>
                                    <select
                                        className='form-control'
                                        value={allotedLocations.loc1 === null ? '-Zip Code 1-' : allotedLocations.loc1}
                                        onChange={e => handleSelectLocation(e, 1)}
                                    >
                                        <option value={null}>-Zip Code 1-</option>
                                        {allUniqueZipCodes.map((item, idx) => (
                                            <option
                                                key={idx}
                                                value={item.zipCode}
                                                disabled={allotedLocations.loc2 === item.zipCode || allotedLocations.loc3 === item.zipCode}
                                            >
                                                {item.state} - {item.zipCode}
                                            </option>
                                        ))}
                                    </select>
                                    <select
                                        className='form-control'
                                        value={allotedLocations.loc2 === null ? '-Zip Code 2-' : allotedLocations.loc2}
                                        onChange={e => handleSelectLocation(e, 2)}
                                        disabled={!allotedLocations.loc1}
                                    >
                                        <option value={null}>-Zip Code 2-</option>
                                        {allUniqueZipCodes.map((item, idx) => (
                                            <option
                                                key={idx}
                                                value={item.zipCode}
                                                disabled={allotedLocations.loc1 === item.zipCode || allotedLocations.loc3 === item.zipCode}
                                            >
                                                {item.state} - {item.zipCode}
                                            </option>
                                        ))}
                                    </select>
                                    <select
                                        className='form-control'
                                        value={allotedLocations.loc3 === null ? '-Zip Code 3-' : allotedLocations.loc3}
                                        onChange={e => handleSelectLocation(e, 3)}
                                        disabled={!allotedLocations.loc2}
                                    >
                                        <option value={null}>-Zip Code 3-</option>
                                        {allUniqueZipCodes.map((item, idx) => (
                                            <option
                                                key={idx}
                                                value={item.zipCode}
                                                disabled={allotedLocations.loc1 === item.zipCode || allotedLocations.loc2 === item.zipCode}
                                            >
                                                {item.state} - {item.zipCode}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                <td>
                                    <i
                                        className='bi bi-save2-fill text-success'
                                        onClick={() => handleLocationSave(data.email)}
                                        style={{ fontSize: '30px', cursor: 'pointer' }}
                                        data-toggle="tooltip"
                                        data-placement="left"
                                        title="Save Location. Disabled values will be saved as empty locations."
                                    ></i>
                                </td>
                            </tr>
                        )) : <center>No Data available</center>}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Employee;
