import axios from 'axios'
import React, { useContext } from 'react'
import { UserContext } from '../App'

axios.defaults.withCredentials = true
const Navbar = () => {
    const { user, login, setLogin } = useContext(UserContext)
    const handleLogout = () => {
        axios.get('https://salesmanagementbackend.basic2ai.info/logout')
            .then(res => {
                // console.log(res.data)
                if (res.status == 200) {
                    setLogin(false)
                    window.location.href = '/'
                }
            })
            .catch(err => {
                console.log(err)
            })
    }
    return (
        <>
            {
                login &&
                <nav className="navbar navbar-expand fixed-top shadow navbar-light bg-light d-flex align-items-center justify-content-between">
                    <a className="navbar-brand" href="/" style={{ fontWeight: '700' }}>Lead Management System</a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <a className="nav-link btn" href="/">Home <span className="sr-only">(current)</span></a>
                        {login && <a href='/register' className='btn'>{user ? user.role === "superAdmin" ? 'Register' : '' : 'Login'}</a>}&nbsp;&nbsp;&nbsp;&nbsp;
                        {login && <button className='btn' onClick={e => handleLogout()}>Logout</button>} &nbsp;&nbsp;&nbsp;
                    </div>
                </nav>
            }

        </>
    )
}

export default Navbar
