import React from 'react';
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';


const Pagination = ({ totalPages, currentPage, itemsPerPage, setItemsPerPage, handlePageChange }) => {
    const generatePageNumbers = () => {
        const pages = [];
        const maxButtons = 5; // Number of buttons to show around the current page

        // Always show first page
        if (currentPage > 1) pages.push(1);

        // Add ellipsis if needed
        if (currentPage > maxButtons) pages.push("...");

        // Add pages around the current page
        const startPage = Math.max(currentPage - Math.floor(maxButtons / 2), 2); // 2 because we already added page 1
        const endPage = Math.min(currentPage + Math.floor(maxButtons / 2), totalPages - 1); // Exclude last page
        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }

        // Add ellipsis if needed
        if (currentPage < totalPages - maxButtons) pages.push("...");

        // Always show the last page
        if (currentPage < totalPages) pages.push(totalPages);

        return pages;
    };

    const pageNumbers = generatePageNumbers();

    return (
        <div className='pagination justify-content-center'>
            <div className='input-group w-25 d-flex align-items-center'>
                <b>Current Customers {itemsPerPage}</b> &nbsp;&nbsp;
                <input
                    className='form-control py-0'
                    style={{ maxWidth: '100px' }}
                    onChange={(e) =>
                        setItemsPerPage(e.target.value > 20 ? e.target.value : itemsPerPage)
                    }
                    min={1}
                />
            </div>
            <button
                className='btn btn-secondary m-1'
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
            >
                <ChevronLeft />
            </button>
            {pageNumbers.map((page, index) => (
                <button
                    key={index}
                    className={`btn border-0 rounded-0 ${
                        currentPage === page ? 'btn-primary' : 'text-dark'
                    } m-1`}
                    onClick={() => typeof page === 'number' && handlePageChange(page)}
                    disabled={typeof page !== 'number'} // Disable ellipsis buttons
                >
                    {page}
                </button>
            ))}
            <button
                className='btn btn-secondary m-1'
                disabled={currentPage === totalPages}
                onClick={() => handlePageChange(currentPage + 1)}
            >
                <ChevronRight />
            </button>
        </div>
    );
};

export default Pagination;
